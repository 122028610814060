// @flow
// スタッフ認証系のredux関連ソース
// state + reducers + actions

import { LoginResponse, StaffInfo } from "../network/api/staffAuth"

export interface StaffAuthState {
  isOpen: boolean,
  authorityTypes: string[],
  callbacks:  (() => void)[],

  token: string,
  有効期限: number,
  staff: StaffInfo,
}
const StaffAuthStateZero = {
  isOpen: false,
  authorityTypes: [],
  callbacks: [],

  token: "",
  有効期限: 0,
  staff: null,
}

export interface StaffAuthOpenActionPayload {
  authorityTypes: string[],
  callback?: () => void,
}

export interface StaffAuthAction {
  type: string,
  payload: undefined | StaffAuthOpenActionPayload | LoginResponse,
}
const STAFF_AUTH_ACTION_TYPE = {
  OPEN : 'OPEN',
  CLOSE : 'CLOSE',
  SET_LOGIN_RESPONSE : 'SET_LOGIN_RESPONSE',
  CLEAR_LOGIN_RESPONSE : 'CLEAR_LOGIN_RESPONSE',
}
export const StaffAuthActions = {
  open: (payload: StaffAuthOpenActionPayload) => ({ type: STAFF_AUTH_ACTION_TYPE.OPEN, payload: payload }),
  close: { type: STAFF_AUTH_ACTION_TYPE.CLOSE },
  setLoginResponse: (payload: LoginResponse) => ({ type: STAFF_AUTH_ACTION_TYPE.SET_LOGIN_RESPONSE, payload: payload }),
  clearLoginResponse: { type: STAFF_AUTH_ACTION_TYPE.CLEAR_LOGIN_RESPONSE },
}

const initialState = StaffAuthStateZero
export function staffAuthReducer(state: StaffAuthState = initialState, action: StaffAuthAction): StaffAuthState {
  switch (action.type) {
    case STAFF_AUTH_ACTION_TYPE.OPEN: return open(state, action.payload)
    case STAFF_AUTH_ACTION_TYPE.CLOSE: return close(state, action.payload)
    case STAFF_AUTH_ACTION_TYPE.SET_LOGIN_RESPONSE: return setLoginResponse(state, action.payload)
    case STAFF_AUTH_ACTION_TYPE.CLEAR_LOGIN_RESPONSE: return clearLoginResponse(state)
    default: return state
  }
}

const open = (state: StaffAuthState, payload: StaffAuthOpenActionPayload): StaffAuthState => {
  const authorityTypes = Array.from(new Set(state.authorityTypes.concat(payload.authorityTypes)));
  state.callbacks.push(payload.callback)
  return {
    ...state,
    isOpen: true,
    authorityTypes: authorityTypes,
  }
}

const close = (state: StaffAuthState): StaffAuthState => {
  return { ...state, isOpen: false, authorityTypes: [], callbacks: [] }
}

const setLoginResponse = (state: StaffAuthState, payload: LoginResponse): StaffAuthState => {
  return {
    ...state,
    token: payload.token,
    有効期限: payload.有効期限,
    staff : payload.staff,
  }
}

const clearLoginResponse = (state: StaffAuthState): StaffAuthState => {
  return {
    ...state,
    token: "",
    有効期限: 0,
    staff: null,
  }
}
