import { postToApiWithJson } from '../Accessor'
import { URL_ENDPOINT_TOP } from '../NetConfig'

export const CheckUrl = URL_ENDPOINT_TOP + '/auth/staff/check/'
export const InitUrl = URL_ENDPOINT_TOP + '/auth/staff/init/'
export const LoginUrl = URL_ENDPOINT_TOP + '/auth/staff/login/'
export const VeinAuthResultUrl = URL_ENDPOINT_TOP + '/auth/staff/veinAuthResult/'
export const LogoutUrl = URL_ENDPOINT_TOP + '/auth/staff/logout/'

export interface TokenParameters {
  token: string,
}

export interface LoginParameters {
  staffId: string,
  password: string,
  authorityTypesJson: string,
  viewUrl: string,
}
export interface VeinAuthResultParameters {
  serverTime: number,
  authorityTypesJson: string,
  viewUrl: string,
}

export interface StaffInfo {
  staffId: string,
  staffName: string,
}

export interface InitResponse {
  result: string, //  OK
  staffInfoList: [StaffInfo],
  serverTime: number
}

export interface LoginResponse {
  result: string, //  OK
  errorMsg: String,
  token: string,
  有効期限: number,
  staff: StaffInfo,
}

export const postToCheck = (
  params: TokenParameters,
  callback: Response => void = () => { },
  signature: string = "data"
) => postToApiWithJson(CheckUrl, params, signature, callback)

export const postToInit = (
  params: null,
  callback: Response => void = () => { },
  signature: string = "data"
) => postToApiWithJson(InitUrl, params, signature, callback)

export const postToLogin = (
  params: LoginParameters,
  callback: Response => void = () => { },
  signature: string = "data"
) => postToApiWithJson(LoginUrl, params, signature, callback)

export const postToVeinAuthResult = (
  params: VeinAuthResultParameters,
  callback: Response => void = () => { },
  signature: string = "data"
) => postToApiWithJson(VeinAuthResultUrl, params, signature, callback)

export const postToLogout = (
  params: TokenParameters,
  callback: Response => void = () => { },
  signature: string = "data"
) => postToApiWithJson(LogoutUrl, params, signature, callback)
