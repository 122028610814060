//@flow

import { combineReducers } from 'redux';

import { Action } from "../interfaces/Action"
import { userReducer } from './userReducers';
import { staffAuthReducer } from "./staffAuthStore"

const reducers = {
  userReducer,
  staffAuthReducer,
}

export default combineReducers<Object, Action>(reducers);