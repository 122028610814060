//@flow

export const RESPONSE_CODE = Object.freeze({
  NG: "NG",
  OK: "OK",
  ERROR: "ERROR",
  UNDEFINED: "UNDEFINED"
})
export type ReponseCode = $Values<typeof RESPONSE_CODE>

export interface Response {
  result: ReponseCode,
  cause?: string
}

export const ResponseZero : Response = {
  result: RESPONSE_CODE.UNDEFINED,
}

export interface ResponseWithId {
  result: ReponseCode,
  cause?: string,
  id: string
}

export const ResponseWithIdZero : ResponseWithId = {
  result: RESPONSE_CODE.UNDEFINED,
  id: ""
}

export const Signature = Object.freeze({
  response: "response",
  responseDelete: "responseDelete",
  responseDeleteMulti: "responseDeleteMulti",
  responseInsert: "responseInsert",
  responseUpdate: "responseUpdate",
  responseUpdateMulti: "responseUpdateMulti",
  items: "items",
  item: "item",
  elements: "elements",
})
export type SignatureType = $Values<typeof Signature>
