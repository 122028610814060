//@flow
import { USER_ACTION_TYPE } from "../constants/userConstants"
import { UserPayload } from "../interfaces/User"

export const UserAction = {
  logout: { type: USER_ACTION_TYPE.LOGOUT },
  request: { type: USER_ACTION_TYPE.REQUEST },
  receiveSuccess: (payload: UserPayload) => ({ type: USER_ACTION_TYPE.RECEIVE_SUCCESS, payload: payload }),
  receiveFailed: (payload: UserPayload) => ({ type: USER_ACTION_TYPE.RECEIVE_FAILED, payload: payload }),
  receiveServerError: (payload: UserPayload) => ({ type: USER_ACTION_TYPE.RECEIVE_SERVER_ERROR, payload: payload })
}
