//@flow
import dotenv from 'dotenv'
dotenv.config()

const urlEndPointTop = () => {
  if (process.env.REACT_APP_API_ENDPOINT_CONFIGURED && process.env.REACT_APP_API_ENDPOINT_CONFIGURED.includes('http')) {
    return process.env.REACT_APP_API_ENDPOINT_CONFIGURED
  } else {
    return process.env.REACT_APP_URL_ENDPOINT_TOP
  }
}

export const URL_ENDPOINT_TOP: string = urlEndPointTop() || ""
