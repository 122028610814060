//@flow
import { Component } from 'react'
import axios from "axios"

import { API_TOKEN } from "../constants/Localstorage"

import { UserAction } from "../actions/userActions"
import { URL_ENDPOINT_TOP } from "./NetConfig"
import { RESPONSE_CODE as RESPONSE_CODE_Temporary } from "./BasicResponse"
import { store } from "../store"
import { StaffAuthActions } from "../reducers/staffAuthStore"

export const RESPONSE_CODE = RESPONSE_CODE_Temporary

export const API_URL = {
  URL_ENDPOINT_TOP: URL_ENDPOINT_TOP,
  FOR_TODAYS_EIGYO_DETAIL: URL_ENDPOINT_TOP + '/eigyo/',
  FOR_TODAYS_EIGYO_START: URL_ENDPOINT_TOP + '/eigyo/start/',
  FOR_TODAYS_EIGYO_SUMMARY: URL_ENDPOINT_TOP + '/eigyo/summary/',
  FOR_TODAYS_EIGYO_DENPYO_LIST: URL_ENDPOINT_TOP + '/eigyo/denpyo/list/',
  FOR_ACTORLIST: URL_ENDPOINT_TOP + '/actorList/',
  FOR_FLOOR_CURRENT_ACTORS: URL_ENDPOINT_TOP + '/floor/currentActors/',
  FOR_FLOOR_CURRENT_TAKUS: URL_ENDPOINT_TOP + '/floor/currentTables/',
  FOR_FLOOR_TIMELINE: URL_ENDPOINT_TOP + '/floor/timeline/',
  FOR_FLOOR_ENTRY: URL_ENDPOINT_TOP + '/floor/config/',
  FOR_FLOOR_ENTRY_POST: URL_ENDPOINT_TOP + '/floor/nyuten/',
  FOR_FLOOR_TAKU_DETAIL: URL_ENDPOINT_TOP + '/floor/taku/',
  FOR_FLOOR_TAKU_DELETE: URL_ENDPOINT_TOP + '/floor/taku/delete/',
  FOR_FLOOR_TAKU_MOVE: URL_ENDPOINT_TOP + '/floor/taku/move/',
  FOR_FLOOR_MENU: URL_ENDPOINT_TOP + '/floor/drinkFood/',
  FOR_FLOOR_DRINK_ORDER_HISTORY: URL_ENDPOINT_TOP + '/floor/taku/order/',
  FOR_FLOOR_DRINK_ORDER_REGISTER: URL_ENDPOINT_TOP + '/floor/taku/order/register/',
  FOR_FLOOR_UPDATE_TAKU_STATE: URL_ENDPOINT_TOP + '/floor/taku/status/',
  FOR_FLOOR_KAIKEI_DETAIL: URL_ENDPOINT_TOP + '/floor/taku/kaikei/',
  FOR_FLOOR_ADJUST_REGISTER: URL_ENDPOINT_TOP + '/floor/taku/adjust/register/',
  FOR_FLOOR_ADJUST_DELETE: URL_ENDPOINT_TOP + '/floor/taku/adjust/delete/',
  FOR_FLOOR_PAYMENT_REGISTER: URL_ENDPOINT_TOP + '/floor/taku/pay/',
  FOR_FLOOR_PAYMENT_DELETE: URL_ENDPOINT_TOP + '/floor/taku/pay/delete/',
  FOR_FLOOR_RECEIPT_PRINT: URL_ENDPOINT_TOP + '/floor/taku/receipt/register/',
  FOR_FLOOR_PRINT: URL_ENDPOINT_TOP + '/floor/taku/print/',
  GIFT_COUNT_URL: URL_ENDPOINT_TOP + '/giftCount/',
  MISSION_LIST_URL: URL_ENDPOINT_TOP + '/missionList/',
  FOR_RANKING_BATCH: URL_ENDPOINT_TOP + '/pos/ranking/ranking/startRankingBatch',
  FOR_RANKING_MONTH_RECOUNT: URL_ENDPOINT_TOP + '/pos/ranking/startRecountMonthRanking',
  FOR_RANKING_YEAR_RECOUNT: URL_ENDPOINT_TOP + '/pos/ranking/startRecountYearRanking',
  QRScan: URL_ENDPOINT_TOP + '/pos/qrcode/qrCodeScanner/',
  CHANGE_ROUTE: URL_ENDPOINT_TOP + '/floor/taku/updateInflowRoute/',
  FOR_FLOOR_KINKEN_SEATSTATUS_CHANGE: URL_ENDPOINT_TOP + '/floor/taku/kinken/seatStatus/',
}

export interface SimpleTenpoIdForm {
  tenpoId: string,
}

export interface SimpleIdForm {
  id: string,
}

export interface ResultIdResponse {
  result: string,
  id: string,
}
export const ResultIdResponseZero: ResultIdResponse = {
  result: "",
  id: "",
}

/* ---------------------------------------------------------------
   売掛での支払いを登録
   --------------------------------------------------------------- */
export interface forFloorUrikakeRegisterParams {
  takuId: string,
  amount: number,
  customerId: string,
  comment: string,
}
export const forFloorUrikakeRegister = (
  params: forFloorUrikakeRegisterParams,
  signature: string = "data"
) => {
  return postToApiWithJson(URL_ENDPOINT_TOP + '/floor/taku/urikake/', params, signature)
}

export const forFloorUrikakeDelete = (
  params: SimpleIdForm,
  signature: string = "data"
) => {
  return postToApiWithJson(URL_ENDPOINT_TOP + '/floor/taku/urikake/delete/', params, signature)
}

/* ---------------------------------------------------------------
   会計を確定
   --------------------------------------------------------------- */
export const postForDenpyoFix = (
  params: { takuId: string, comment: string },
  signature: string = "data"
) => {
  return postToApiWithJson(URL_ENDPOINT_TOP + '/floor/taku/kaikei/fix/', params, signature)
}

/* ---------------------------------------------------------------
   支払い登録
   --------------------------------------------------------------- */
export interface PaymentRegisterForm {
  takuId: string,
  methodId: string,
  amount: number,
}
export const postPaymentRegister = (
  params: PaymentRegisterForm,
  signature: string = "data",
  callback: Function = () => { },
) => {
  return postToApiWithJson(URL_ENDPOINT_TOP + '/floor/taku/pay/', params, signature, callback)
}

/* ---------------------------------------------------------------
   API用 アクセッサー関数
   --------------------------------------------------------------- */

const criteriaToOmit = ["createdAt", "createdBy", "updatedAt", "updatedBy", "deletedAt", "deletedBy", "deleted"]

export const responseTypeEnum = Object.freeze({
  arraybuffer: 'arraybuffer',
  document: 'document',
  json: 'json',
  text: 'text',
  stream: 'stream',
  blog: 'blob',
})

export type responseTypeEnum型 = $Values<typeof responseTypeEnum>

export const postToApiWithJson =
  (url: string,
    json: {} = {},
    signature: string = "data",
    callback: any => void = (responseData) => { },
      responseType: responseTypeEnum型 = responseTypeEnum.json) : any =>
        async (component: Component<any, any>) => {
          const params = new URLSearchParams()
          await Object.keys(json).forEach(key => {
            if (!criteriaToOmit.includes(key)) params.append(key, json[key])
          })
          await axios.create({
            withCredentials: true,
            responseType: responseType
          }).post(url, params, { headers: { "content-type": "application/x-www-form-urlencoded" } })
            .then(async response => {
              console.log('request : ', url, json)
              console.log('response row:', response)
              console.log('response: ', url, response.data)
              if (response && response.data && response.data.result === RESPONSE_CODE.NG) {
                if (typeof component.props.dispatch === "function") {
                  await component.props.dispatch(UserAction.receiveFailed(response.data));
                } else {
                  alert("======エラーが発生しました======\n\n" + ((response && response.data) ? response.data.cause : ""))
                }
                await component.setState({ [signature]: response.data })
                await callback(response.data)
              } else if (response && response.data) {
                await component.setState({ [signature]: response.data })
                await callback(response.data)
              }
            }).catch(async error => {
              if (error.response) {
                console.log('request : ', url, json)
                console.log("======API ERROR======");
                console.log(error)
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)

                if (error.response.status === 401) { // 401が帰ってきたら無言で問答無用でログアウトし、状態無視でログインへ飛ばす
                  // axios.create({
                  //   withCredentials: true
                  // }).post(URL_ENDPOINT_TOP + "/logout/", {}).then()
                  await localStorage.removeItem(API_TOKEN)
                  window.location.href = "/login"
                } else if (error.response.status === 403) { // 403の場合、スタッフ認証モーダルを表示
                  store.dispatch(StaffAuthActions.open({
                    authorityTypes: error.response.data.required,
                    callback: () => postToApiWithJson(url, json, signature, callback, responseType)(component)
                  }))
                } else {
                  displayAlert(error, component)
                }

              } else if (error.request) {
                alert("======原因不明のAPIエラーが発生しました Code:X2 ======\n\n")
                console.log(error.request)
              } else {
                alert("======原因不明のAPIエラーが発生しました Code:X3 ======\n\n")
                console.log('Error', error.message)
              }
            })
        }

export const postToApiWithJsonMultipart =
  (url: string, json: {} = {}, signature: string = "data", callback: any => void = (responseData) => { }) =>
async (component: Component<any, any>) => {
  // const params = new URLSearchParams()
  const params = new FormData()
  await Object.keys(json).forEach(key => {
    if (!criteriaToOmit.includes(key)) params.append(key, json[key])
  })
  await axios.create({
    withCredentials: true
  }).post(url, params, { headers: { 'content-type': 'multipart/form-data' } })
    .then(async response => {
      console.log('request : ', url, json)
      console.log('response: ', url, response.data)
      if (response && response.data && response.data.result === RESPONSE_CODE.NG) {
        if (typeof component.props.dispatch === "function") {
          await component.props.dispatch(UserAction.receiveFailed(response.data));
        } else {
          alert("======エラーが発生しました======\n\n" + ((response && response.data) ? response.data.cause : ""))
        }
        await component.setState({ [signature]: response.data })
        await callback(response.data)
      } else if (response && response.data) {
        await component.setState({ [signature]: response.data })
        await callback(response.data)
      }
    }).catch(async error => {
      if (error.response) {
        console.log('request : ', url, json)
        console.log("======API ERROR======");
        console.log(error)
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)

        if (error.response.status === 401) { // 401が帰ってきたら無言で問答無用でログアウトし、状態無視でログインへ飛ばす
          // axios.create({
          //   withCredentials: true
          // }).post(URL_ENDPOINT_TOP + "/logout/", {}).then()
          await localStorage.removeItem(API_TOKEN)
          window.location.href = "/login"
        } else if (error.response.status === 403) { // 403の場合、スタッフ認証モーダルを表示
          store.dispatch(StaffAuthActions.open({
            authorityTypes: error.response.data.required,
            callback: () => postToApiWithJsonMultipart(url, json, signature, callback)(component)
          }))
        } else {
          displayAlert(error, component)
        }

      } else if (error.request) {
        alert("======原因不明のAPIエラーが発生しました Code:X2 ======\n\n")
        console.log(error.request)
      } else {
        alert("======原因不明のAPIエラーが発生しました Code:X3 ======\n\n")
        console.log('Error', error.message)
      }
    })
}

const displayAlert = async (error, component) => {
  if (typeof component.props.dispatch === "function" && error.response.data) {
    await component.props.dispatch(UserAction.receiveServerError(error.response.data))
  } else if (error.response.data && error.response.data.cause) {
    alert("======API エラーが発生しました======\n\n"
      + error.response.data.cause)
  } else {
    alert("======原因不明のAPIエラーが発生しました Code:X1 ======\n\n")
  }
}
